const vn = {
  common: {
    readMore: 'Xem thêm',
    name: 'Tên',
    email: 'Email',
    phone: 'Điện thoại',
    messagePlaceholder: 'Nói cho chúng tôi về những thử thách của bạn',
    submit: 'Gửi',
    submitSuccess: 'Thông tin của bạn đã được gửi thành công.',
    promiseSendEmail: 'Chúng tôi sẽ gửi thêm thông tin vào email của bạn',
    returnHome: 'Về trang chủ',
    thankYou: 'Cám ơn',
    footer: 'Công ty TNHH Thuế và Tư vấn KPMG & ',
    companyName: 'Công ty TNHH Giải Pháp Phần Mềm Tường Minh.',
    viewLaunchingEvent: 'Xem sự kiện ra mắt',
    cooperate: 'Innovation as a Service: ',
    downloadTitle: 'Nhấn vào đây để tải tệp tin',
    launchingEventMetaTitle: 'TMA và KPMG ra mắt Innovation as a service',
    launchingEventMetaDescription: 'Innovation as a Service - dịch vụ được ra mắt bởi TMA và KPMG - giúp các công ty giải quyết nhiều vấn đề, thách thức bằng cách áp dụng những công nghệ mới nhất, tạo ra các sản phẩm và dịch vụ sáng tạo hơn, cạnh tranh hơn.',
  },
  menu: {
    "Home": "Trang chủ",
    "Ecosystem": 'Hệ sinh thái',
    "Innovation as a service": 'Dịch vụ đổi mới sáng tạo',
    'Why Choose Us': 'Tại Sao chọn chúng tôi',
    'Online Assessment': "Đánh giá năng lực",
    "Community": 'Cộng đồng',
    "Contact Us": 'Liên hệ'
  },
  serviceSubMenu: {
    "Inspire & Insight": 'Cảm hứng & Chia sẻ nghiên cứu',
    "Acceleration / Idea Crowd-Sourcing": 'Chương trình tăng tốc / Tìm ý tưởng từ đám đông',
    "Acceleration / Challenge": 'Chương trình tăng tốc / Thử thách',
    "Training": 'Đào tạo',
    "Co-Creation": 'Đồng sản xuất',
    "Advisory / Strategy": 'Tư vấn / Chiến lược',
    "Advisory / Go to Market": 'Tư vấn / Tiếp cận thị trường',
  },
  homePage: {
    'INSPIRE & INSIGHT': 'Cảm hứng & chia sẻ nghiên cứu',
    'ACCELERATION': 'Chương trình tăng tốc',
    'Idea Crowd-Sourcing': 'Tìm ý tưởng từ đám đông',
    'Challenge': 'Thử thách',
    Training: 'Đào tạo',
    DetailEvent: 'Accelerate Digital Transformation with Innovation as a service',
    Advisory: 'Tư vấn',
    'Go to Market': 'Tiếp cận thị trường',
    Strategy: 'Chiến lược',
    'Co-Creation': 'Đồng Sản Xuất',
    homeBanner: {
      message1: 'Đưa kinh nghiệm đổi mới sáng tạo',
      message2: 'của thế giới vào Việt Nam',
    },
    homeBanner2: {
      title: 'Bạn đã sẵn sàng để đổi mới?',
      message1: 'Xác định mức độ sẵn sàng đổi mới sáng tạo của doanh nghiệp với bảng đánh giá năng lực từ KPMG.',
      button: 'Bắt đầu Đánh giá'
    },
    innovationEcosystem: {
      title: "Hệ sinh thái đổi mới sáng tạo",
      organizeImg: require("../assets/image/innovation-ecosystem-vn.svg"),
      content: {
        content1: "Liên minh KPMG & TMA hướng đến loại bỏ tính chất phức tạp của mô hình đổi mới sáng tạo nhằm giúp doanh nghiệp tập trung vào thế mạnh căn bản – tạo ra các ý tưởng kinh doanh đột phá.",
        content2: "Doanh nghiệp không cần phải đầu tư đội ngũ IT với chi phí cao để xây dựng các giải pháp hay tiêu tốn tài nguyên trong việc cập nhật các công nghệ mới. Chúng tôi đảm nhận phần việc khó khăn này cho bạn.",
        content3: "Chúng tôi có đội ngũ hơn 4,000 chuyên gia tại Việt Nam trong nhiều lĩnh vực như: kinh doanh, thuế, và pháp lý. Đặc biệt, chúng tôi có am hiểu sâu sắc trong các lĩnh vực công nghệ như blockchain, IoT, trí tuệ nhân tạo, điện toán đám mây, dữ liệu lớn, công cụ trò chuyện tự động (chatbot), ứng dụng doanh nghiệp (DMS, ERP, HRMS, CRM, Kinh doanh thông minh (Business intelligence)) và an ninh mạng. Với hàng nghìn chuyên gia và kinh nghiệm thực tiễn, chúng tôi tự tin trong việc hiện thực hóa các ý tưởng kinh doanh thành giải pháp mang tính đột phá."
      }
    },
    whyInnovation: {
      title: 'Vì sao cần dịch vụ đổi mới sáng tạo?',
      des: 'Biến ý tưởng thành giải pháp số',
      block1: {
        title: 'Bằng cách nào Liên minh KPMG & TMA giúp doanh nghiệp sáng tạo và biến ý tưởng thành giải pháp cho tổ chức?',
        content1: 'Thành lập ban điều hành đổi mới sáng tạo trong doanh nghiệp ',
        content2: 'Tổ chức hội thảo đổi mới sáng tạo nhằm tìm kiếm ý tưởng kinh doanh đột phá cho doanh nghiệp (Liên minh KPMG & TMA)',
        content3: 'Đồng hành phát triển các bản thử nghiệm giúp trải nghiệm nhanh giải pháp (Liên minh KPMG & TMA)',
        content4: 'Thực nghiệm và đánh giá kết quả giải pháp (Doanh nghiệp thực hiện cùng Liên minh KPMG & TMA)',
        content5: 'Triển khai giải pháp (Doanh nghiệp thực hiện cùng Liên minh KPMG & TMA)',
      },
      block2: {
        title: 'Doanh nghiệp cần đầu tư gì trong việc thực hiện Dịch vụ đổi mới sáng tạo?',
        content1: 'Ban điều hành đổi mới sáng tạo: chúng tôi đề nghị thành lập hội đồng đổi mới sáng tạo trong doanh nghiệp. Đây là việc điều động nội bộ và không phát sinh chi phí thực hiện.',
        content2: 'Dịch vụ đổi mới sáng tạo: tùy theo nhu cầu của doanh nghiệp, các dịch vụ với chi phí phù hợp sẽ được thực hiện bởi các chuyên gia tư vấn, kỹ sư công nghệ nhằm hỗ trợ việc biến ý tưởng thành giải pháp số.',
        contactUs: 'Liên hệ chúng tôi để bắt đầu hành trình đổi mới sáng tạo của bạn.'
      },
      block3: {
        title: 'Các ví dụ về mục tiêu kinh doanh và lợi ích kỳ vọng được mang lại từ Dịch vụ đổi mới sáng tạo?',
        content1: 'Gia tăng lợi nhuận',
        content2: 'Giảm thiểu chi phí vận hành',
        content3: 'Tối đa hóa giải pháp kinh doanh',
        content4: 'Tự động hóa quy trình',
        content5: 'Số hóa dữ liệu',
        content6: 'Giải quyết các khó khăn về công nghệ',
        textDes: 'Liên minh KPMG & TMA có khả năng thực hiện biến đổi ý tưởng thành giải pháp số trong 4 tuần'
      }
    },
    theInnovationLifecycle: {
      title: 'Vòng đời đổi mới sáng tạo',
      textDes: 'Doanh nghiệp sẽ được trải nghiệm dịch vụ đòn bẩy phát triển từ liên minh hàng đầu của các chuyên gia, kỹ sư giàu kinh nghiệm với chi phí phù hợp, thời gian thực hiện ngắn.',
      lifeCycleImg: require("../assets/image/our-approach-vn.png"),
      lifeCycleImgMobile: require("../assets/image/our-approach-mobile-vn.png"),
    },
    innovationAsAService: {
      title: 'Dịch vụ đổi mới sáng tạo',
      ourApproach: 'Phương pháp tiếp cận',
      inspireInsight: {
        title: 'Cảm hứng & chia sẻ nghiên cứu',
        blockContent1: {
          title: "Hội thảo đổi mới sáng tạo",
          textDes:
            "Khuyến khích đổi mới sáng tạo thông qua các hội thảo mang các chủ đề của start-up, chuyên gia và lãnh đạo đầu ngành.",
        },
        blockContent2: {
          title: "Chương trình Roadshow về ngành",
          textDes:
            "Kết nối với các tập đoàn dẫn đầu thị trường, start-up theo các chủ đề quan tâm",
        },
      },
      training: {
        title: "Đào tạo",
        content: 'Cung cấp các khóa đào tạo căn bản và theo nhu cầu của doanh nghiệp trong việc nâng cao các kỹ năng về hoạch định chiến lược, phương pháp tư duy, kiến thức công nghệ'
      },
      advisory: {
        title: 'Tư vấn',
        blockContent1: {
          title: "Chiến lược",
          textDes:
            "Chiến lược đổi mới sáng tạo bao gồm sự đánh giá năng lực đổi mới sáng tạo củadoanh nghiệp, xác định tầm nhìn đổi mới, mục tiêu đổi mới, danh mục đầu tư, kế hoạch thực hiện và các yếu tố khác như: cấu trúc doanh nghiệp, qui trình và nguồn lực bổ trợ.",
        },
        blockContent2: {
          title: "Tiếp cận thị trường",
          textDes:
            "Sau khi xây dựng hoàn chỉnh giải pháp, doanh nghiệp cần một chiến lược tiếp cận thị trường. Các chuyên gia từ phía chúng tôi luôn đồng hành cùng doanh nghiệp trong quá trình đưa ra quyết định đầu tư cũng như sẵn sàng tư vấn về các vấn đề phát sinh.",
        },
      },
      coCreation: {
        title: 'Đồng Sản Xuất',
        content: `
        <li>Lên kế hoạch phát triển sản phẩm</li>
        <li>Tư vấn giải pháp công nghệ</li>
        <li>Prototyping</li>
        <li>Kiểm chứng ý tưởng (Proof of Concept)</li>
        <li>Nghiên cứu & phát triển (R&D)</li>
        <li>Thiết kế giao diện & trải nghiệm người dùng (Thiết kế UX/UI)</li>
        <li>Phát triển sản phẩm</li>
        <li>Kiểm thử sản phẩm</li>
        <li>Chuyển đổi hệ thống</li>
        <li>Dịch vụ quản lý hệ thống</li>`
      },
      acceleration: {
        title: 'Chương trình tăng tốc',
        blockContent1: {
          title: "Tìm ý tưởng từ đám đông",
          textDes:
            "Thiết lập cơ chế trực tuyến và ngoại tuyến trong nội bộ để sàng lọc và ươm mầm các ý tưởng",
        },
        blockContent2: {
          title: "Thử thách",
          textDes:
            "Tổ chức cuộc thi công khai hoặc nội bộ, được thiết kế nhằm giải quyết những vấn đề xung quanh các mục tiêu kinh doanh của doanh nghiệp trong quá trình phát triển theo hướng đổi mới sáng tạo (các ưu tiên, chiến lược, tình huống cần thay đổi)",
        },
      },
      download: {
        textDes: 'Bạn đang tìm kiếm dịch vụ đổi mới sáng tạo phù hợp nhất với công ty mình?',
        linkLabel: 'Tải về Thông tin Dịch vụ Đổi mới sáng tạo'
      }
    },
    whyChooseUs: {
      title: 'Vì sao chọn chúng tôi',
      content1: 'Chúng tôi là một đối tác đáng tin cậy trong lĩnh vực Kinh Doanh & Công Nghệ với gần 50 năm kinh nghiệm trong việc giúp đỡ các khách hàng đổi mới và biến đổi trong tiến trình hội nhập với những thách thức của kỷ nguyên 4.0.',
      content2: 'Chúng tôi cung cấp các dịch vụ toàn diện cho các lĩnh vực Kinh doanh, Tư vấn, Công nghệ kỹ thuật số; Các công nghệ kỹ thuật tiên tiến được chúng tôi áp dụng nhằm giúp bạn giải quyết bất kì thử thách nào trong kinh doanh.',
      content3: 'Chúng tôi có khả năng và chuyên môn sâu rộng trong lĩnh vực công nghệ, từ công nghệ doanh nghiệp đến các công nghệ kỹ thuật số 4.0 mới và đang phổ biến. ',
      content4: 'Chúng tôi cung cấp mô hình đổi mới sáng tạo mang giá trị quốc tế nhưng với chi phí phù hợp. Mô hình cho phép hành trình đổi mới sáng tạo bắt đầu nhanh chóng, các ý tưởng được hiện thực hóa và tung ra thị trường chỉ trong vòng 4 tuần.',
    },
    getInTouch: {
      title: 'Liên hệ với chúng tôi',
      textDes: ' Vui lòng chia sẻ với chúng tôi để nhận các hỗ trợ về đổi mới sáng tạo. Chúng tôi sẽ phản hồi trong thời gian sớm nhất.',
      company: 'Công ty TNHH Thuế và Tư vấn KPMG',
      location: 'Lầu 10, tòa nhà Sunwah, 115 Nguyễn Huệ, Quận 1, Hồ Chí Minh, Việt Nam',
      tel: '+84 (28) 3821 9266',
    }
  },
  accelerationIdeaPage: {
    ideaCrowdSourcingImg: require('../assets/image/acceleration-idea-vn.svg')
  },
  launchingEvent: {
    headLine: 'Innovation as a service: ',
    title: 'Đưa kinh nghiệm đổi mới sáng tạo của thế giới vào Việt Nam',
    paragraph1: `Nền kinh tế Việt Nam đang chuyển dịch từ phát triển theo chiều rộng chiều sâu, nên động lực phát triển không còn là tài nguyên, đất đai, giá nhân công thấp mà là công nghệ và sáng tạo.`,
    paragraph2: 'Hiện nay Covid-19 đang thay đổi mạnh mẽ nền kinh tế Việt Nam và thế giới.  Tất cả các doanh nghiệp đều phải thay đổi, thích nghi để có thể tiếp tục phát triển. Liên tục đổi mới sáng tạo và ứng dụng công nghệ mới sẽ giúp các doanh nghiệp nâng cao hiệu quả, năng lực cạnh tranh và tiết kiệm thời gian, nhân lực. Tuy nhiên quá trình đổi mới sáng tạo chưa thực sự phổ biến ở Việt Nam nên các doanh nghiệp còn lúng túng. Innovation as a service – dịch vụ hợp tác chiến lược giữa TMA và KPMG sẽ giúp các doanh nghiệp giải quyết thách thức này bằng cách áp dụng các quy trình và phương pháp đổi mới sáng tạo từ các nước hàng đầu, kết hợp với các công nghệ mới nhất (trí tuệ nhân tạo, dữ liệu lớn, Blockchain, IoT, điện toán đám mây…).',
    paragraph3: ``,
    paragraph4: `Dịch vụ đổi mới sáng tạo (Innovation as a service) là mô hình hợp tác 3 bên (Khách hàng – KPMG – TMA), cả 3 sẽ hợp tác chặt chẽ nhằm phân tích các thử thách của thị trường, đề ra các sản phẩm và giải pháp mới. Khách hàng không chỉ nhận được sản phẩm đột phá mà còn học hỏi được phương pháp và quy trình đổi mới sáng tạo để tiếp tục áp dụng vào tất cả các bộ phận, đưa đổi mới sáng tạo trở thành một phần của văn hóa doanh nghiệp, tạo động lực phát triển mới.`,
    paragraph5: `Như một phần của Innovation as a service, nền tảng Hackathon Việt Nam được ra mắt để hỗ trợ cho quá trình đổi mới của các doanh nghiệp. Nền tảng này hoạt động theo mô hình tận dụng những sáng kiến của cộng đồng và các chuyên gia để giải quyết những vấn đề do doanh nghiệp đưa ra. Đây là sân chơi cho những người đam mê sáng tạo, nơi mọi người có thể thực hành, học hỏi, tham gia thử thách và nhận các giải thưởng. `,
    paragraph6: `TMA là công ty công nghệ hàng đầu Việt Nam với 23 năm kinh nghiệm trong lĩnh vực phát triển phần mềm, nhận sự tín nhiệm của hàng trăm khách hàng là các tập đoàn lớn đến từ 27 quốc gia trên toàn thế giới. Đội ngũ hơn 2.500 kỹ sư của TMA đang triển khai các giải pháp công nghệ mới cho khách hàng trong nhiều lĩnh vực như: Tài chính ngân hàng, y tế, giáo dục, giao thông vận tải, thương mại điện tử…`,
    paragraph7: `KPMG bắt đầu hoạt động tại Việt Nam từ năm 1994 với 3 văn phòng ở Hà Nội, TP.HCM và Đà Nẵng. Sau 26 năm hoạt động tại Việt Nam đã giúp KPMG có kinh nghiệm phong phú về thị trường và trở thành  công ty tư vấn lớn nhất Việt Nam. KPMG cũng đã thực hiện thành công hơn 20 dự án công nghệ trong vòng 36 tháng qua. `,
    paragraph8: `KPMG bắt đầu hoạt động tại Việt Nam từ năm 1994 với 3 văn phòng ở Hà Nội, TP.HCM và Đà Nẵng. Sau 26 năm hoạt động tại Việt Nam đã giúp KPMG có kinh nghiệm phong phú về thị trường và trở thành  công ty tư vấn lớn nhất Việt Nam. KPMG cũng đã thực hiện thành công hơn 20 dự án công nghệ trong vòng 36 tháng qua. `,
    chairman1Img: require('../assets/image/chairman-kpmg-vn.png'),
    chairman2Img: require('../assets/image/chairman-tmasolution-vn.png'),
    exploreMore: 'Xem thêm:',
  },
  inspireInsightPage: {
    'INSPIRE & INSIGHT': 'Cảm hứng & chia sẻ nghiên cứu',
    'Innovation Workshop': 'Hội thảo đổi mới sáng tạo',
    'Industry Roadshow': 'Chương trình Roadshow về ngành',
    'An intensive, curated experience with transformative startups, innovative companies and leading corporations to achieve your growth, investment, innovation objectives.': 'Trải nghiệm chuyên sâu với các start-up đột phá, doanh nghiệp đổi mới sáng tạo và chiếm lĩnh thị trường nhằm mang lại cho doanh nghiệp các ví dự thực tiễn, bài học về phát triển, đầu tư, và xác định xu hướng đổi mới.',
    "Innovation workshops are designed to promote employees' innovation mindset, cultivate an internal innovative culture and practice; and enhance their ability to implement innovation strategies more effectively.": 'Hội thảo đổi mới sáng tạo được thiết kế nhằm thúc đẩy nhận thức của nhân viên về tư duy đổi mới, phương pháp kiến tạo văn hóa sáng tạo và củng cố các kỹ năng thực thi chiến lược đổi mới sáng tạo một cách hiệu quả.',
    'Our Focus': 'Trọng tâm của chúng tôi',
    'Explore industry trends and innovation': 'Tìm kiếm xu hướng ngành và đổi mới sáng tạo',
    'Artificial intelligence': 'Trí tuệ nhân tạo',
    'Internet of things': 'Internet vạn vật',
    'Big data': 'Dữ liệu lớn',
    'Robotics': 'Ứng dụng Robot',
    'Cloud computing': 'Điện toán đám mây',
    'Blockchain': 'Blockchain ',
    'Automotive': 'Công nghệ ô tô',
    'Fintech': 'Công nghệ tài chính',
    'Retail': 'Bán lẻ',
    'Real estate': 'Bất động sản',
    Healthcare: 'Y tế',
    'Our Delivery': 'Các giá trị mang lại từ chúng tôi',
    'Establish connections across an innovative external network and promote innovation': 'Hình thành các kết nối trải rộng hệ thống đổi mới sáng tạo và không ngừng thúc đẩy chúng',
    'Startup Story': 'Câu chuyện startup',
    'Technology Insights': 'Thấu hiểu công nghệ',
    'Market Insights': 'Thấu hiểu thị trường',
    'New ways of working': 'Phương thức làm việc mới'
  },
  accelerationIdeaPage: {
    imageContent: require('../assets/image/acceleration-idea-vn.svg'),
    // `${require('../assets/image/acceleration-idea-en.svg')}`: 
    'The challenge poses a problem to a diverse group of people, allowing them to address an urgent and relevant theme. Initial proposals will be selected and incubated to high quality ideas. This opens up a company-wide innovation process to employees, and fosters cross-functional collaboration.': 'Một vấn đề thách thức sẽ được đề ra cho một nhóm người với nhiều chuyên môn khác nhau, họ được phép đưa ra những giải pháp tức thời. Những đề xuất ban đầu sẽ thông qua chọn lọc và ươm mầm trở thành những ý tưởng chất lượng. Quá trình này góp phần mở ra một dây chuyền đổi mới sáng tạo cho các nhân viên trong toàn công ty, đồng thời thúc đẩy sự hợp tác giữa các phòng ban. ',
    images: {
      vn: require('../assets/image/acceleration-idea-vn.svg'),
      en: require('../assets/image/acceleration-idea-en.svg')
    }
  },
  'accelerationChallengePage': {
    Acceleration: 'Chương trình tăng tốc',
    name: 'Thử thách',
    description: 'KPMG đóng góp trong việc thiết kế và vận hành một cuộc thi đổi mới sáng tạo mở xoay quanh mục tiêu kinh doanh của doanh nghiệp. Chương trình này mang mục đích gắn kết kiến thức và tài nguyên trong nội bộ doanh nghiệp với những dịch vụ từ KPMG và các đối tác liên minh. Từ đó giúp doanh nghiệp theo đuổi những định hướng phát triển đổi mới sáng tạo.',
    imageContent: require('../assets/image/acceleration-idea-vn.svg'),
    Input: 'Nguồn tài nguyên',
    'Leading Corporate': 'Doanh nghiệp hàng đầu',
    "Growth objectives": "Các mục tiêu phát triển",
    "Industry experts and talent": "Các chuyên gia và nhân tài trong lĩnh vực",
    'KPMG - Innovation Service Provider': 'Dịch vụ đổi mới sáng tạo cung cấp bởi KPMG',
    'Professional innovation services': 'Dịch vụ đổi mới sáng tạo chuyên nghiệp ',
    'Alliance experts': 'Các chuyên gia từ liên minh',
    'Resources from startup ecosystem': 'Tài nguyên từ hệ sinh thái khởi nghiệp',
    'Ideation': 'Lên ý tưởng',
    'By most efficiency methodology, we work together for seeing the root of problem & innovative solutions': 'Bằng phương pháp hiệu quả nhất, chúng tôi sẽ cùng doanh nghiệp tìm ra gốc rễ của vấn đề và đưa ra những giải pháp sáng tạo.',
    'Strategy': 'Chiến lược',
    'If you want to win a big game, a clear path to success need to be identified': 'Nếu bạn muốn chiến thắng cuộc chơi lớn, một chiến lược thành công được hoạch định rõ ràng là điều không thể thiếu.',
    'Prioritization': 'Sự ưu tiên',
    'We prioritize the innovation that minimizes disruption risk and maximizes growth.': 'Chúng tôi sẽ ưu tiên những ý tưởng đổi mới sáng tạo giúp giảm tối đa các nguy cơ gián đoạn đồng thời đẩy mạnh sự phát triển.',
    'Case for Change': 'Tình huống cần thay đổi',
    'We predict risks and mitigation plan for them. That will guarantee for our expectation': 'Các dự đoán rủi ro và kế hoạch phòng ngừa sẽ được chuẩn bị nhằm đảm bảo đạt được các mục tiêu kỳ vọng.',
    'Prototype': 'Lên mô hình mẫu',
    'Proof of Concept': 'Bản thử nghiệm',
    'Within 2-3 weeks, your idea can be touch-and-feel. In digital era, speed is number one competitive advantage': 'Trong kỷ nguyên số, tốc độ là lợi thế cạnh tranh số một.Chúng tôi sẽ phát triển bản thử nghiệm từ ý tưởng của bạn chỉ trong 2-3 tuần.',
    'User Testing': 'Đánh giá từ người dùng',
    'We can test out the market & get feedbacks after we have POC. This is the great input value for Go-to-market decision': 'Doanh nghiệp có thể kiểm tra và có những phản hồi từ thị trường sau khi giới thiệu Bản thử nghiệm. Đây là nguồn tham khảo giá trị cho việc tiến tới kế hoạch tung ra thị trường.'
  },
  tranningPage: {
    contentPage: 'Chương trình đào tạo của chúng tôi đặt mục tiêu hỗ trợ doanh nghiệp trong việc nâng cao khả năng thực hiện hoạt động đổi mới sáng tạo và hướng đến trở thành đơn vị dẫn đầu xu hướng trong ngành. Các khóa học sẽ trang bị đến từng cá nhân kỹ năng lãnh đạo độc lập và thúc đẩy sáng kiến chuyển đổi kinh doanh. ',
    'KPMG Next': 'KPMG Next',
    'Innovation Culture': 'Văn hóa đổi mới sáng tạo',
    'Customer and Operation (SCM)': 'Khách hàng và vận hành (SCM)',
    'Financial Management': 'Quản trị hoạt động tài chính',
    'People & Change': 'Nhân sự & Thay đổi',
    'Risk management': 'Quản trị rủi ro',
    'Innovation and Strategic Management': 'Quản trị chiến lược và đổi mới sáng tạo',
    'Design Thinking': 'Phương pháp tư duy',
    'Project Management': 'Quản trị dự án',
    'Digital Transformation': 'Chuyển đổi số',
    'Technology Strategy': 'Chiến lược công nghệ',
    'Emerging Technology': 'Công nghệ mới nổi',
    'Strategic planning & business management skills': 'Hoạch định chiến lược & kỹ năng quản trị kinh doanh',
    'Core Innovative Skills': 'Kỹ năng đổi mới sáng tạo cốt lõi',
    'Technical knowledge and sense': 'Kiến thức công nghệ'

  },
  coCreationPage: {
    'Product Planning': 'Lên kế hoạch phát triển sản phẩm',
    'Solution Consulting': 'Tư vấn giải pháp công nghệ',
    'Prototyping': 'Prototyping',
    'Proof of Concept': 'Kiểm chứng ý tưởng (Proof of Concept)',
    'R&D': 'Nghiên cứu & phát triển (R&D)',
    'UX/UI Design': 'Thiết kế giao diện & trải nghiệm người dùng (Thiết kế UX/UI)',
    'Product Development': 'Phát triển sản phẩm',
    'Product Testing': 'Kiểm thử sản phẩm',
    'Porting & Migration': 'Chuyển đổi hệ thống',
    'IT Managed Services': 'Dịch vụ quản lý hệ thống',
    'End-to-end solution from Ideas to Product': 'Giải pháp toàn diện từ Ý tưởng đến Sản phẩm',
    'Services': 'Dịch vụ',
    'Solutions': 'Các giải pháp',
    'Go Digital': 'Chuyển đổi số',
    'Go Cloud': 'Chuyển hóa đám mây',
    'Go Smart': 'Thông minh hơn',
    'Go Online': 'Trực tuyến hơn',
    'Go Automation': 'Tự động hơn',
    'Go Interactive': 'Tương tác hơn',
    'Go Mobile': 'Di động hơn',
    'Go Integrated': 'Tích hợp hơn',
    'Go Secure': 'Bảo mật hơn'
  },
  strategyPage: {
    contentPage: 'Chiến lược đổi mới sáng tạo bao gồm sự đánh giá về các yếu tố: đổi mới trong bối cảnh doanh nghiệp, tầm nhìn đổi mới, mục tiêu đổi mới, danh mục đầu tư, kế hoạch thực hiện, tài nguyên và ngân sách, cấu trúc, văn hóa, các giá trị và hệ sinh thái của doanh nghiệp.',
    contentBelow: 'Kết quả đạt được sẽ là một bức tranh đa chiều, trong đó cung cấp cho doanh nghiệp sự đánh giá toàn diện về định hướng đổi mới sáng tạo, các lĩnh vực cần cải tiến, phân tích thị trường, đối thủ cạnh tranh cũng như chỉ ra các lợi thế mà doanh nghiệp có thể tận dụng.'
  },
  goToMarketPage: {
    contentPage: 'Sau khi xây dựng hoàn chỉnh giải pháp, doanh nghiệp cần một chiến lược tiếp cận thị trường. Các chuyên gia từ phía chúng tôi luôn đồng hành cùng doanh nghiệp trong quá trình đưa ra quyết định đầu tư cũng như sẵn sàng tư vấn về các vấn đề phát sinh.'
  },
  submitPage: {
    title: 'Bắt đầu Đánh giá mức độ sẵn sàng Đổi mới sáng tạo ngay bây giờ!',
    desciption: 'Nhận đánh giá ban đầu về năng lực đổi mới sáng tạo của doanh nghiệp và đo lường với mức độ trung bình  trên toàn thế giới. Chỉ trong vài phút, chúng tôi sẽ cung cấp kết quả của riêng bạn. ',
    fullname: 'Họ tên',
    jobtitle: 'Chức danh',
    company: 'Công ty',
    email: 'Email',
    mobile: 'Số điện thoại',
    industry: 'Ngành',
    companyrevenuesize: 'Doanh thu',
    billion: 'tỷ VNĐ',
    numberOfEmploymee: 'Số lượng nhân viên',
    people: 'người',
    between: 'từ',
    takeAssessment: 'Bắt đầu đánh giá'
  },
  questionnairePage: {
    commentLabel: 'Vui lòng chia sẻ với chúng tôi chủ đề thảo luận, hội thảo về Đổi mới kỹ thuật số (Digital Innovation) mà bạn quan tâm:',
    viewresult: "Xem kết quả",
    questionsAnswered: "câu hỏi đã trả lời",
    tellYourThinking: "Nói với chúng tôi suy nghĩ của bạn"
  },
  resultPage: {
    pageTitle: "Kết quả",
    thankful1: "CẢM ƠN QUÝ DOANH NGHIỆP ĐÃ DÀNH THỜI GIAN THỰC HIỆN BẢNG ĐÁNH GIÁ MỨC ĐỘ SẴN SÀNG ĐỔI MỚI SÁNG TẠO.",
    thankful2: "Chúng tôi hy vọng rằng quý doanh nghiệp sẽ bắt đầu hành trình Đổi mới sáng tạo thành công và tối ưu hóa nguồn lực của mình với sự tư vấn từ KPMG!",
    download: "Tải về Báo cáo Khảo sát CIO toàn cầu 2020",
    sponsored: "Tài trợ bởi Liên minh KPMG & TMA",
    contact: "Vui lòng liên hệ chúng tôi tại:"
  },
  ResidentialRealEstate: {
    title1: "Đổi Mới Sáng Tạo Số, Khai Phá Tiềm Năng",
    title2: "Bất Động Sản Nhà Ở",
    tDate: "NGÀY",
    tHour: "GIỜ",
    tPlace: "ĐỊA ĐIỂM",
    cDate: "25 Tháng Hai, 2021",
    cHour: "10:00 - 11:30",
    cPlace: "Sky Lounge, Tầng 22, Hotel des Arts Saigon MGallery Collection 78 Nguyễn Thị Minh Khai, Quận 3, Thành phố Hồ Chí Minh ",
    block2: {
      title: "Chương trình hội thảo bao gồm",
      text1: "Giá trị Đổi mới sáng tạo số mang lại cho doanh nghiệp?",
      text2: "Tọa đàm: Đổi mới sáng tạo số để dẫn dắt cuộc chơi",
      text3: "Phương pháp đúng đắn để Đổi mới sáng tạo số",
    },
    block3: {
      text1: "Nếu có bất cứ thắc mắc, hoặc cần thêm thông tin về chương trình, quý khách vui lòng liên hệ",
      contact1: "Ms. Truc Nguyen",
      contact2: "Ms. Oanh Bui",
    },
    block5: {
      text1: "Chúng tôi rất hân hạnh được đón tiếp quý khách tại buổi tọa đàm ",
      text2: "Bất động sản nhà ở: Đổi mới sáng tạo số, khai phá tiềm năng"
    },
    block6: {
      title: "Giới thiệu",
      text1: "Quỹ đất ngày càng hạn hẹp khiến sự cạnh tranh trong lĩnh vực bất động sản trở nên ngày càng khốc liệt. Các doanh nghiệp trong ngành liệu có thể tiếp tục duy trì sức cạnh tranh bằng phương pháp hiện tại hay cần phải tìm kiếm phương pháp thực thi phá cách, ứng dụng công nghệ để xây dựng tầm nhìn đột phá:",
      button1: "Tạo thêm loại hình tài sản mới",
      button2: "Mở rộng hệ sinh thái",
    },
    block7: {
      title: "Diễn giả"
    },
    block8: {
      title: "Hình thức",
      text1: "Sự kiện dành cho Lãnh đạo cấp cao của các công ty /tập đoàn Bất Động sản Nhà ở (Nhóm tập trung) đang dẫn dắt hoặc phụ trách hoạt động liên quan tới Đổi mới sáng tạo, Chuyển đổi số hoặc các mô hình kinh doanh mới mới ứng dụng Đổi mới sáng tạo",
      text2: "Sự kiện này nằm trong chuỗi hoạt động “Inno+ series 2021” được tiếp nối với hoạt động thứ 02 là buổi Tư vấn riêng chỉ dành cho các doanh nghiệp được mời tham dự.",
    }
  }
};

export default vn;